import { Box } from "@mui/material";

interface ICardComponentProps {
  card: Card;
  children: React.ReactNode;
}

const CardComponent = ({ card, children }: ICardComponentProps) => {
  if (card.front_image_uri) {
    return (
      <Box key={card.scryfall_id}>
        <img
          alt="magic card"
          className="mx-auto rounded-lg"
          style={{ borderRadius: "15px", maxWidth: "100%" }}
          width={300}
          height="auto"
          src={card.front_image_uri}
        />
        <img
          alt="magic card"
          className="mx-auto rounded-lg"
          width={300}
          height="auto"
          style={{ borderRadius: "15px", maxWidth: "100%" }}
          src={card.back_image_uri}
        />
        <div className="mx-auto text-center">{children}</div>
      </Box>
    );
  } else {
    return (
      <Box key={card.scryfall_id}>
        <img
          alt="magic card"
          className="mx-auto rounded-lg"
          style={{ borderRadius: "15px", maxWidth: "100%" }}
          width={300}
          height="auto"
          src={card.image_uri}
        />
        <div className="mx-auto text-center">{children}</div>
      </Box>
    );
  }
};

export default CardComponent;
