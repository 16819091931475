// import {CardModel,CardArrayModel} from "../models/redux-models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialCardState: CardModel = {
  cards: [],
  currentCard: undefined,
  savedCards: [],
  loading: true
};

const cardSlice = createSlice({
  name: "card",
  initialState: initialCardState,
  reducers: {
    saveCard(state, action: PayloadAction<Card>) {
      state.savedCards.push(action.payload)
    },
    setCards(state, action: PayloadAction<Card[]>) {
      state.cards = action.payload;
    },
    setCurrentCard(state, action: PayloadAction<Card>) {
      state.currentCard = action.payload;
    },
    setSavedCards(state, action: PayloadAction<Card[]>) {
      state.savedCards = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    }
  },
});

export const { saveCard, setCards, setCurrentCard, setSavedCards, setLoading } = cardSlice.actions;
export default cardSlice.reducer;