import CardComponent from "../components/card";
import PageLayout from "../components/PageLayout";
import { Box, Stack } from "@mui/material";
import { useAppSelector } from "../redux-hooks";

const Results = () => {
  const { card } = useAppSelector((state) => state);
  const savedCards = card.savedCards;

  return (
    <PageLayout>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 4 }}
      >
        {savedCards.map((card: Card) => {
          return (
            <CardComponent card={card}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Stack direction="row" spacing={2}>
                  <a href={card.scryfall_uri || ""}>Scryfall</a>
                  <a href={card.edhrec_uri || ""}>EDHREC</a>
                </Stack>
              </Box>
            </CardComponent>
          );
        })}
      </Box>
    </PageLayout>
  );
};

export default Results;
