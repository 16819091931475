import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const NextButton = ({ onClick }: React.DOMAttributes<HTMLButtonElement>) => {
  return (
    <Button variant="outlined" onClick={onClick}>
      <ArrowForwardIcon />
    </Button>
  );
};
export default NextButton;
