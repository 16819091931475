import { Button } from "@mui/material"
import ArchiveIcon from "@mui/icons-material/Archive"
const SaveButton = ({ onClick }: React.DOMAttributes<HTMLButtonElement>) => {
  return (
    <Button
      variant="outlined"
      onClick={onClick}
    >
      <ArchiveIcon />
    </Button>
  );
};
export default SaveButton;
