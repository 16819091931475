import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { createClient } from "@supabase/supabase-js";

export async function getCards(): Promise<Card[]> {
  const supabaseUrl = "https://wzmbcafiyubnjscrwdgn.supabase.co";
  const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
  const supabase = createClient(supabaseUrl, supabaseKey!);

  const lsCards = localStorage.getItem("cards");
  let localCards;
  let filteredCards;

  if (lsCards) {
    localCards = JSON.parse(lsCards);
    return localCards;
  } else {
    try {
      const { data, error, status } = await supabase.from("cards").select();

      if (error && status !== 406) {
        throw error;
      }
      if (data) {
        localStorage.setItem("cards", JSON.stringify(data));
        return data;
      }
    } catch (e) {
      console.error(e);
    }
  }
  return [];
}

export async function getSavedCards(): Promise<Card[]> {
  const supabaseUrl = "https://wzmbcafiyubnjscrwdgn.supabase.co";
  const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
  const supabase = createClient(supabaseUrl, supabaseKey!);

  const lsCards = localStorage.getItem("cards");
  let localCards;
  let filteredCards;

  if (lsCards) {
    localCards = JSON.parse(lsCards);
    return localCards;
  } else {
    try {
      const { data, error, status } = await supabase.from("cards").select();

      if (error && status !== 406) {
        throw error;
      }
      if (data) {
        localStorage.setItem("cards", JSON.stringify(data));
        return data;
      }
    } catch (e) {
      console.error(e);
    }
  }
  return [];
}
