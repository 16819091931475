import {setCards } from "./cardSlice";
import { AnyAction } from "@reduxjs/toolkit";
import { ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { getCards } from "../services/cardService";
import { filterCards } from "../lib/filters";

// export const cardActions = cardSlice.actions;

export const fetchCards = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async (dispatch, getState) => {
    const { filter, card} = getState();
    if (card.cards.length === 0) {
      const response: Card[] = await getCards();
      const filteredCards = filterCards(response, filter);
      dispatch(setCards(filteredCards));
    }
  };
};


// export const fetchSavedCards = (): ThunkAction<
//   void,
//   RootState,
//   unknown,
//   AnyAction
// > => {
//   return async (dispatch) => {
//     const response: Card[] = await getSavedCards();

//     dispatch(setLoading(false));
//     dispatch(setSavedCards(response));
//   };
// };
