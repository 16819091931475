import React from "react";
import { useNavigate } from "react-router-dom";
import PageLayout from "../components/PageLayout";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Stack,
  Switch,
} from "@mui/material";
import FormNav from "../components/FormNav";
import { useAppDispatch, useAppSelector } from "../redux-hooks";
import { setColorless } from "../store/filterSlice";

const Colorless = () => {
  const navigate = useNavigate();
  const {filter} = useAppSelector((state) => state);
  const colorless = filter.colorless;
  const dispatch = useAppDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setColorless(event.target.checked));
  };

  const handleSubmit = () => {
    navigate(`/commanders`);
  };

  const handleSkip = () => {
    navigate(`/commanders`);
  };

  return (
    <PageLayout>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Stack>
          <FormControl component="fieldset" sx={{ m: 3 }}>
            <FormLabel component="legend">
              Include colorless commanders?
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={colorless} onChange={handleChange} />}
                label="Yes"
              />
            </FormGroup>
            <FormNav handleSubmit={handleSubmit} handleSkip={handleSkip} />
          </FormControl>
        </Stack>
      </Box>
    </PageLayout>
  );
};

export default Colorless;
