import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";

interface ManaValueSliderProps {
  handleChange: (event: Event, value: number | number[], activeThumb: number) => void
  manaValue: number | number[]
}

const ManaValueSlider = ({handleChange, manaValue}: ManaValueSliderProps) => {

  function valueText(value: number) {
    return `${value}`
  }

    return (
        <Box sx={{width: '100%'}}>
        <Slider
          getAriaLabel={() => 'Mana Value'}
          value={manaValue}
          getAriaValueText={valueText}
          min={1}
          max={13}
          valueLabelDisplay="auto"
          onChange={handleChange}
        />
       </Box>
    )
}

export default ManaValueSlider;