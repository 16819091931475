import { useSession, useSupabaseClient } from "@supabase/auth-helpers-react";
import {
  AppBar,
  Badge,
  Box,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import ArchiveIcon from "@mui/icons-material/Archive";
import InfoIcon from "@mui/icons-material/Info";

import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux-hooks";

const PageLayout = ({ children }: any) => {
  const supabase = useSupabaseClient();
  const session = useSession();
  const navigate = useNavigate();
  const { card } = useAppSelector((state) => state);
  const savedCards = card.savedCards;

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            {/* <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton> */}
            <Typography
              variant="h6"
              component="a"
              noWrap
              href="/"
              sx={{
                flex: "grow",
                mr: 2,
                display: { xs: "flex", md: "flex" },
                fontWeight: 700,
                color: "white",
              }}
            >
              Deck Sketch
            </Typography>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              onClick={() => navigate("/saved")}
              aria-label="saved"
            >
              <Badge badgeContent={savedCards.length} color="secondary">
                <ArchiveIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              onClick={() => navigate("/about")}
              aria-label="about"
            >
              <InfoIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
      <Container>
        {session && (
          <a
            className="flex items-center rounded-md p-3 hover:bg-gray-50"
            onClick={() => supabase.auth.signOut()}
          >
            <span className="m-2 text-base font-medium text-gray-900">
              Sign Out
            </span>
          </a>
        )}
        <div className="">{children}</div>
      </Container>
    </>
  );
};

export default PageLayout;
