import { configureStore } from "@reduxjs/toolkit";
import positionSlice from "./positionSlice";
import cardSlice from "./cardSlice";
import filterSlice from "./filterSlice";

const store = configureStore({
  reducer: {
    position: positionSlice,
    card: cardSlice,
    filter: filterSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
