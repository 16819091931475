import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";

const BackButton = ({ onClick }: React.DOMAttributes<HTMLButtonElement>) => {
  return (
    <Button variant="outlined" onClick={onClick}>
      <ArrowBackIcon />
    </Button>
  );
};
export default BackButton;
