import { useNavigate } from "react-router-dom";
import PageLayout from "../components/PageLayout";
import ManaValueSlider from "../components/ManaValueSlider";
import { Box, Stack, Typography } from "@mui/material";
import FormNav from "../components/FormNav";
import { useAppSelector, useAppDispatch } from "../redux-hooks";
import { setManaValue } from "../store/filterSlice"

import * as React from "react";

const ManaValue = () => {
  const navigate = useNavigate();
  const {filter} = useAppSelector((state) => state);
  const manaValue = filter.manaValue;
  const dispatch = useAppDispatch();
  const handleChange = (event: Event, newValue: number | number[]) => {
    dispatch(setManaValue(newValue as number[]));

  };

  const handleSubmit = () => {
    navigate("/include-exclude");
  };

  const handleSkip = () => {
    navigate("/include-exclude");
  };

  return (
    <PageLayout>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Stack>
          <Typography variant="h6">Mana Value</Typography>
          <Typography variant="body1">Mana value is how much your commander will cost to cast.</Typography>
          <Typography variant="body1">{manaValue.join(" - ")}</Typography>
          <ManaValueSlider handleChange={handleChange} manaValue={manaValue} />
          <FormNav handleSubmit={handleSubmit} handleSkip={handleSkip} />
        </Stack>
      </Box>
    </PageLayout>
  );
};

export default ManaValue;
