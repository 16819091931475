import React from "react";
import "./App.css";
import Home from "./pages/home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ManaValue from "./pages/mana-value";
import IncludeExclude from "./pages/include-exclude";
import Recommendations from "./pages/recommendations";
import Colors from "./pages/colors";
import Results from "./pages/results";
import CssBaseline from "@mui/material/CssBaseline";
import Vanilla from "./pages/vanilla";
import Colorless from "./pages/colorless";
import About from "./pages/about";


function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/mana-value",
      element: <ManaValue />,
    },
    {
      path: "/include-exclude",
      element: <IncludeExclude />,
    },
    {
      path: "/color-count",
      element: <Colors />,
    },
    {
      path: "/commanders",
      element: <Recommendations />,
    },
    {
      path: "/vanilla",
      element: <Vanilla />,
    },
    {
      path: "/colorless",
      element: <Colorless />,
    },
    {
      path: "/saved",
      element: <Results />,
    },
    {
      path: "/about",
      element: <About />,
    }
  ]);

  return (
    <div className="App">
      <CssBaseline />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
