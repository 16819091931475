// import Head from "next/head";
import React, { useEffect, useState } from "react";
import CardComponent from "../components/card";
import SaveButton from "../components/SaveButton";
import NextButton from "../components/NextButton";
import PageLayout from "../components/PageLayout";
import { useNavigate } from "react-router-dom";
import BackButton from "../components/BackButton";
import { Box, Link, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../redux-hooks";
import { fetchCards } from "../store/cardActions";
import { increment, decrement } from "../store/positionSlice";
import { saveCard } from "../store/cardSlice";

const Recommendations = () => {
  const [, setPicks] = useState(0);
  // const [loading, setLoading] = useState(true);
  // const [currentCard, setCurrentCard] = useState<Card | undefined>(undefined);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {card, position} = useAppSelector((state) => state);
  const allCards = card.cards;
  const currentPosition = position.value;

  const currentCard = allCards[currentPosition];

  useEffect(() => {
    dispatch(fetchCards());
  });

  async function goBack() {
    if (currentPosition > 0) {
      dispatch(decrement())
    }
  }

  function nextCard() {
    if (currentPosition + 1 <= allCards.length - 1) {
      dispatch(increment())

      return allCards[currentPosition]["scryfall_id"];
    } else {
      return false;
    }
  }

  // function restart() {
  //   console.log("restart");
  //   // updateFilters({});
  //   navigate("/");
  // }

  async function submitMatch(card: Card, liked: boolean) {
    if (liked) {
      console.log(`Saved: ${card.scryfall_id}`);
      dispatch(saveCard(card))
      // updateSaved(card);
    }

    // const { data: match } = await supabase
    //   .from("matches")
    //   .insert({
    //     // user_id: router.query["session-id"],
    //     card_id: cardId,
    //     liked,
    //     created_at: new Date(),
    //     anonymous: true,
    //   })
    //   .single();

    setPicks((picks) => picks + 1);
    if (currentPosition + 1 <= allCards.length) {
      nextCard()
      // navigate(`/commanders`);
    } else {
      navigate("/saved");
    }
  }

  return (
    <PageLayout>
      <Box display="flex" justifyContent="center" alignItems="center" mt="2rem">
        <Stack>
          <Typography align="center" variant="h6" component="div">
            {currentPosition}/{allCards.length - 1}
          </Typography>
          {currentCard && (
            <CardComponent key={currentCard.scryfall_id} card={currentCard}>
              <Stack
                display="flex"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Stack direction="row" spacing={2}>
                  {currentPosition >= 1 && <BackButton onClick={() => goBack()} />}
                  {currentPosition <= allCards.length - 1 && (
                    <SaveButton
                      onClick={async () => await submitMatch(currentCard, true)}
                    />
                  )}
                  {currentPosition + 1 <= allCards.length && (
                    <NextButton
                      onClick={async () =>
                        await submitMatch(currentCard, false)
                      }
                    />
                  )}
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Link
                    className="m-2 rounded-lg text-violet-500"
                    href={currentCard.scryfall_uri || ""}
                  >
                    Scryfall
                  </Link>
                  <Link
                    className="m-2 rounded-lg text-gray-500"
                    href={currentCard.edhrec_uri || ""}
                  >
                    EDHREC
                  </Link>
                </Stack>
              </Stack>
            </CardComponent>
          )}
        </Stack>
      </Box>
    </PageLayout>
  );
};

export default Recommendations;
