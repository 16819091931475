import PageLayout from "../components/PageLayout";
// import { useSupabaseClient } from "@supabase/auth-helpers-react";
import React from "react";
import { Box, Stack, Typography } from "@mui/material";

const About = () => {
  return (
    <PageLayout>
      <Box display="flex" mt="2rem" justifyContent="center" alignItems="center">
        <Stack spacing={2}>
          <Typography>
            DeckSketch is a fan-made site reach out to me here on twitter{" "}
            <a href="https://twitter.com/decksketch">@decksketch</a>.
          </Typography>
          <Typography>
            Data is pulled from <a href="https://mtgjson.com">mtgjson</a>,
            images are pulled from <a href="https://scryfall.com">Scryfall</a>.
          </Typography>
          <Typography>
            The site is hosted on <a href="https://vercel.com">Vercel</a> in
            combination with <a href="https://supabase.com">Supabase</a> on the
            backend. The technology used is React on the front end with{" "}
            <a href="http://mui.com">Material UI</a>, and Postgres database on
            the backend.
          </Typography>
          <Typography>
            Magic: The Gathering is a Trademark of Wizards of the Coast, Inc.
            and Hasbro, Inc. Decksketch is unafilliated.
          </Typography>
        </Stack>
      </Box>
    </PageLayout>
  );
};

export default About;
