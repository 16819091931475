import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialFilterState: Filters = {
  includedColors: {included: [], excluded: []},
  manaValue: [1,13],
  colors: [],
  vanilla: undefined,
  colorless: undefined,
};

const filterSlice = createSlice({
  name: "filter",
  initialState: initialFilterState,
  reducers: {
    setManaValue(state, action: PayloadAction<number[]>) {
      state.manaValue = action.payload;
    },
    addColor(state, action: PayloadAction<number>) {
      state.colors.push(action.payload);
    },
    removeColor(state, action: PayloadAction<number>) {
      state.colors = state.colors.filter((color: number) => color !== action.payload);
    },
    setColors(state, action: PayloadAction<number[]>) {
      state.colors = action.payload;
    },

    setVanilla(state, action: PayloadAction<undefined | boolean>) {
      state.vanilla = action.payload;
    },
    setColorless(state, action: PayloadAction<boolean>) {
      state.colorless = action.payload;
    },

    addIncludedColor(
      state,
      action: PayloadAction<string>
    ) {
      state.includedColors.included.push(action.payload);
    },
    removeIncludedColor(
      state,
      action: PayloadAction<string>
    ) {
      state.includedColors.included = state.includedColors.included.filter((color: string) => color != action.payload);
    },
    addExcludedColor(
      state,
      action: PayloadAction<string>
    ) {
      state.includedColors.excluded.push(action.payload);
    },
    removeExcludedColor(
      state,
      action: PayloadAction<string>
    ) {
      state.includedColors.excluded = state.includedColors.excluded.filter((color: string) => color != action.payload);
    },
    // setFilter(state, action: PayloadAction<Filters>) {
    //   state.filters = action.payload;
    // },
  },
});

export const { addIncludedColor, removeIncludedColor, addExcludedColor, removeExcludedColor, setManaValue, addColor, removeColor, setColors, setVanilla, setColorless } = filterSlice.actions;
export default filterSlice.reducer;