function manaValueFilter(filter: number[] | undefined, card: Card) {
  if (
    filter === undefined ||
    (card.cmc >= filter[0] && card.cmc <= filter[1])
  ) {
    return true;
  } else {
    return false;
  }
}

function colorsFilter(filter: number[] | undefined, card: Card) {
  if (
    filter &&
    (Object.keys(filter).length === 0 ||
      filter.some((cidl) => cidl == card.coloridentity.length))
  ) {
    return true;
  } else {
    return false;
  }
}

function includedColorsFilter(filter: string[] | undefined, card: Card) {
  if (
    filter === undefined ||
    filter.length === 0 ||
    card.coloridentity.length === 0 ||
    card.coloridentity.some((cid) => filter.includes(cid))
  ) {
    return true;
  } else {
    return false;
  }
}

function excludedColorsFilter(filter: string[] | undefined, card: Card) {
  if (
    filter === undefined ||
    filter.length === 0 ||
    (filter.length > 0 &&
      !card.coloridentity.some((cid) => filter.includes(cid)))
  ) {
    return true;
  } else {
    return false;
  }
}

function colorlessFilter(filter: boolean | undefined, card: Card) {
  if (
    filter === undefined ||
    filter === false ||
    (filter === true && card.coloridentity.length === 0)
  ) {
    return true;
  } else {
    return false;
  }
}

function vanillaFilter(filter: boolean | undefined, card: Card) {
  const vanillaNames = [
    "Kasimir the Lone Wolf",
    "Lady Orca",
    "Yargle, Glutton of Urborg",
    "Barktooth Warbeard",
    "Sivitri Scarzam",
    "The Lady of the Mountain",
    "Tobias Andrion",
    "Jerrard of the Closed Fist",
    "Jasmine Boreal",
    "Jedit Ojanen",
    "Sir Shandlar of Eberyn",
    "Isamaru, Hound of Konda",
    "Torsten Von Ursus",
  ];

  if (
    filter === undefined ||
    filter === false ||
    (filter === true && vanillaNames.includes(card.name))
  ) {
    return true;
  } else {
    return false;
  }
}

function applyFilters(filters: Filters, card: Card) {
  const filterKeys = Object.keys(filters);
  const filterResults = [];

  if (filterKeys.includes("manaValue")) {
    filterResults.push(manaValueFilter(filters.manaValue, card));
  }

  if (filterKeys.includes("includedColors")) {
    if (filters.includedColors && filters.includedColors.included.length > 0) {
      filterResults.push(
        includedColorsFilter(filters.includedColors.included, card)
      );
    }
  }

  if (filterKeys.includes("includedColors")) {
    if (filters.includedColors && filters.includedColors.excluded.length > 0) {
      filterResults.push(
        excludedColorsFilter(filters.includedColors.excluded, card)
      );
    }
  }

  if (filterKeys.includes("colors")) {
    filterResults.push(colorsFilter(filters.colors, card));
  }

  if (filterKeys.includes("vanilla")) {
    filterResults.push(vanillaFilter(filters.vanilla, card));
  }

  if (filterKeys.includes("colorless")) {
    filterResults.push(colorlessFilter(filters.colorless, card));
  }

  return filterResults.every((filter) => filter === true);
}

function filterCards(cards: Card[], filters: Filters | undefined): Card[] {
  return cards.filter((card: Card) => {
    if (filters && Object.keys(filters).length > 0) {
      const shouldShow = applyFilters(filters, card);

      return shouldShow;
    } else {
      return true;
    }
  });
}

export {
  vanillaFilter,
  colorlessFilter,
  colorsFilter,
  excludedColorsFilter,
  includedColorsFilter,
  manaValueFilter,
  applyFilters,
  filterCards,
};
