import { ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import PageLayout from "../components/PageLayout";
import ColorCountCheckbox from "../components/ColorCountCheckbox";
import { Box, FormControl, FormLabel, FormGroup, Stack } from "@mui/material";
import FormNav from "../components/FormNav";
import { useAppDispatch, useAppSelector } from "../redux-hooks";
import { addColor, removeColor } from "../store/filterSlice";

const Colors = () => {
  const navigate = useNavigate();
  const {filter} = useAppSelector((state) => state);
  const includedColors = filter.includedColors;
  const colors = filter.colors;
  const dispatch = useAppDispatch();

  let availableColorCount;

  if (
    filter.includedColors.included.length === 1 &&
    filter.includedColors.excluded.length === 5
  ) {
    availableColorCount = 1;
  } else if (includedColors) {
    availableColorCount = 5 - includedColors.excluded.length;
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;

    if (checked) {
      dispatch(addColor(Number(value)))
    } else {
      dispatch(removeColor(Number(value)))
    }
  };

  const handleSubmit = () => {
    if (colors && colors["1"]) {
      navigate(`/colorless`);
    } else {
      navigate("/vanilla");
    }
  };

  const handleSkip = () => {
    navigate(`/colorless`);
  };

  return (
    <PageLayout>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Stack>
          <h1>Color Count</h1>

          <FormControl component="fieldset" sx={{ m: 3 }}>
            <FormLabel component="legend">
              How many colors do you want to play?
            </FormLabel>
            <FormGroup>
              <ColorCountCheckbox
                value="1"
                label="Mono Color"
                availableColorCount={availableColorCount}
                handleChange={handleChange}
              />
              <ColorCountCheckbox
                value="2"
                label="Two Color"
                availableColorCount={availableColorCount}
                handleChange={handleChange}
              />
              <ColorCountCheckbox
                value="3"
                label="Three Color"
                availableColorCount={availableColorCount}
                handleChange={handleChange}
              />
              <ColorCountCheckbox
                value="4"
                label="Four Color"
                availableColorCount={availableColorCount}
                handleChange={handleChange}
              />
              <ColorCountCheckbox
                value="5"
                label="Five Color"
                availableColorCount={availableColorCount}
                handleChange={handleChange}
              />
            </FormGroup>
            <FormNav handleSubmit={handleSubmit} handleSkip={handleSkip} />
          </FormControl>
        </Stack>
      </Box>
    </PageLayout>
  );
};

export default Colors;
