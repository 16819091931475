import { Checkbox, FormControlLabel } from "@mui/material";
const ColorCountCheckbox = ({
  availableColorCount,
  handleChange,
  value,
  label,
}: any) => {
  function isDisabled(value: string): boolean {
    if (value > availableColorCount) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          value={value}
          onChange={handleChange}
          disabled={isDisabled(value)}
        />
      }
      label={label}
    />
  );
};

export default ColorCountCheckbox;
