import PageLayout from "../components/PageLayout";
import { useNavigate } from "react-router-dom";
import { Box, FormControl, FormGroup, FormLabel, Stack } from "@mui/material";
import ColorIncludeCheckbox from "../components/ColorIncludeCheckbox";
import FormNav from "../components/FormNav";
import {
  addIncludedColor,
  removeIncludedColor,
  addExcludedColor,
  removeExcludedColor,
} from "../store/filterSlice";
import { useAppDispatch, useAppSelector } from "../redux-hooks";

const IncludeExclude = () => {
  const { filter } = useAppSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const includedColors = filter.includedColors;
  console.log(includedColors);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    const color = value[1];

    if (value[0] === "i") {
      if (checked) {
        dispatch(addIncludedColor(color));
      } else {
        dispatch(removeIncludedColor(color));
      }
    }

    if (value[0] === "e") {
      if (checked) {
        dispatch(addExcludedColor(color));
      } else {
        dispatch(removeExcludedColor(color));
      }
    }
  };

  const handleSubmit = () => {
    navigate("/color-count");
  };

  const handleSkip = () => {
    navigate("/color-count");
  };

  return (
    <PageLayout>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Stack>
          <Box>
            <FormControl component="fieldset" sx={{ m: 3 }}>
              <FormLabel component="legend">Must include</FormLabel>
              <FormGroup>
                <ColorIncludeCheckbox
                  value="iW"
                  label="White"
                  includedColors={includedColors}
                  handleChange={handleChange}
                />
                <ColorIncludeCheckbox
                  value="iU"
                  label="Blue"
                  includedColors={includedColors}
                  handleChange={handleChange}
                />
                <ColorIncludeCheckbox
                  value="iB"
                  label="Black"
                  includedColors={includedColors}
                  handleChange={handleChange}
                />
                <ColorIncludeCheckbox
                  value="iR"
                  label="Red"
                  includedColors={includedColors}
                  handleChange={handleChange}
                />
                <ColorIncludeCheckbox
                  value="iG"
                  label="Green"
                  includedColors={includedColors}
                  handleChange={handleChange}
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" sx={{ m: 3 }}>
              <FormLabel component="legend">Must exclude</FormLabel>
              <FormGroup>
                <ColorIncludeCheckbox
                  value="eW"
                  label="White"
                  includedColors={includedColors}
                  handleChange={handleChange}
                />
                <ColorIncludeCheckbox
                  value="eU"
                  label="Blue"
                  includedColors={includedColors}
                  handleChange={handleChange}
                />
                <ColorIncludeCheckbox
                  value="eB"
                  label="Black"
                  includedColors={includedColors}
                  handleChange={handleChange}
                />
                <ColorIncludeCheckbox
                  value="eR"
                  label="Red"
                  includedColors={includedColors}
                  handleChange={handleChange}
                />
                <ColorIncludeCheckbox
                  value="eG"
                  label="Green"
                  includedColors={includedColors}
                  handleChange={handleChange}
                />
              </FormGroup>
            </FormControl>
          </Box>
          <FormNav handleSubmit={handleSubmit} handleSkip={handleSkip} />
        </Stack>
      </Box>
    </PageLayout>
  );
};

export default IncludeExclude;
