import { Box, Button, Stack } from "@mui/material";

const FormNav = ({ handleSubmit, handleSkip }: any) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Stack direction="row" spacing={2}>
        <Button variant="outlined" onClick={handleSkip}>
          Skip
        </Button>
        <Button variant="outlined" onClick={handleSubmit}>
          Next
        </Button>
      </Stack>
    </Box>
  );
};

export default FormNav;
