import PageLayout from "../components/PageLayout";
// import { useSupabaseClient } from "@supabase/auth-helpers-react";
import React from "react";
import { Box, Button, List, ListItem, Stack, Typography } from "@mui/material";
// import { Auth, ThemeSupa } from "@supabase/auth-ui-react";
// import {
//   useUser,
//   // useSession
// } from "@supabase/auth-helpers-react";
// import { v4 as uuidv4 } from "uuid";
// import ResumeDialog from "../components/ResumeDialog";


const Home = () => {
  // const user = useUser();
  // const [anonId, setAnonId] = useState("");


  // function newAnonId() {
  //   if (!user && anonId === "") {
  //     const newAnonId = uuidv4();
  //     setAnonId(newAnonId);
  //   }

  //   return newAnonId;
  // }

  // let userId;
  // let anonymous;

  // if (user) {
  //   userId = user.id;
  //   anonymous = false;
  // } else {
  //   userId = anonId || newAnonId();
  //   anonymous = true;
  // }

  // useEffect(() => {
  //   updateResume(false);
  // }, []);

  // useEffect(() => {
  //   if (resume) {
  //     setOpen(true);
  //   }
  // });

  // const [open, setOpen] = React.useState(false);

  // const handleNo = () => {
  //   setOpen(false);
  // };

  // const handleYes = () => {
  //   setOpen(false);
  //   navigate("/commanders");
  // };

  return (
    <PageLayout>
        <Box display="flex"  mt="2rem" justifyContent="center" alignItems="center">
          <Stack spacing={2}>
            <Typography variant="h6">
              Discover a commander the easy way.
            </Typography>

            <List>
              <ListItem>1. Select or skip filters.</ListItem>
              <ListItem>2. Save cards that interest you.</ListItem>
              <ListItem>3. Pick a commander to build.</ListItem>
            </List>
            <Button variant="outlined" href="/mana-value">
              Get Started
            </Button>
          </Stack>
        </Box>
      {/* <ResumeDialog open={open} handleNo={handleNo} handleYes={handleYes} /> */}
    </PageLayout>
  );
};

export default Home;
