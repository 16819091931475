import { Checkbox, FormControlLabel } from "@mui/material";
const ColorIncludeCheckbox = ({ includedColors, handleChange, value, label }: any) => {

    function isDisabled(value: string): boolean {
        const color = value[1]
        
        if (value[0] === "i") {
            return includedColors.excluded.includes(color)
        }

        if (value[0] === "e") {
            return includedColors.included.includes(color)
        }

        return true
    }


  return (
    <FormControlLabel
      control={
        <Checkbox value={value} onChange={handleChange} disabled={isDisabled(value)} />
      }
      label={label}
    />
  );
};

export default ColorIncludeCheckbox;
