// import {PositionModel,PositionArrayModel} from "../models/redux-models";
import { createSlice } from "@reduxjs/toolkit";

interface PositionState {
  value: number;
}
const initialState: PositionState = { value: 0 };

const positionSlice = createSlice({
  name: "position",
  initialState,
  reducers: {
    increment(state) {
      state.value++;
    },

    decrement(state) {
      state.value--;
    },
  },
});

export const { increment, decrement } = positionSlice.actions;
export default positionSlice.reducer;
